import React  from "react";
import { ReactSVG } from "react-svg";
// import CustomMap from "./CustomMap";
// import DirectionMap from "./DirectionMap";
// import { APIProvider } from "@vis.gl/react-google-maps";
// import DirectionMap from '../SimpleMap.gif';
// import MapDirectionStroke from '../MapDirection.gif';
import OfficialMarianGarden from '../OFFICIAL_MarianGardens_Simplified.png';
// import DonorMap from '../DonorMapB.png';

function Location({ donordatadetails }) {

// console.log(donordatadetails);
// const subdomain = window.location.hostname.split('.')[1]
const url = window.location.href; 
console.log(url);

  return (
    <div className="donorInfo">
    {
      // donordatadetails.location ? <div>{donordatadetails.donormapimage_b}</div> : ''
    }

    { donordatadetails.full_name  ?  <h1>Welcome {donordatadetails.full_name}!</h1> : <h2>Welcome to RCBO Campus!</h2> }
    
    {
      donordatadetails.location.length === 2 ||  donordatadetails.location.length === 3 ?
        <h2>YOUR NAME IS LOCATED AT  <span style={{fontWeight:700}}><em>{donordatadetails.location}</em></span> OF THE WALL OF GRATITUDE.</h2> 
      : 
      donordatadetails.location.length === 4 ? 
      <h2>YOUR NAME IS LOCATED AT  <span style={{fontWeight:700}}><em>{donordatadetails.location}</em></span> OF THE MARTYRS MEMORIAL WALL.</h2>
      :
      <h2>YOUR NAME IS LOCATED AT  <span style={{fontWeight:700}}><em>{donordatadetails.location}</em></span> </h2>
    }
             

        {
          donordatadetails.location ? <h2>  Please follow the directions below to get there.</h2> : ''
        }
        <div className="textGratitudeWall">WALL OF GRATITUDE</div>
        <div className="textMartyrWall">MARTYRS MEMORIAL WALL</div>

        {
        
          donordatadetails.location === '2A' ||  donordatadetails.location === '2B' || donordatadetails.location === '3A' || donordatadetails.location === '3B' 
         

          ?
          <div className="circle1">{donordatadetails.location}</div>
          :
          donordatadetails.location === '4A' ||  donordatadetails.location === '4B' || donordatadetails.location === '5A' || donordatadetails.location === '5B' ||
          donordatadetails.location === '6A' ||  donordatadetails.location === '6B' 

          ?
          <div className="circle4">{donordatadetails.location}</div>
          :
          donordatadetails.location === '7A' ||  donordatadetails.location === '7B' || donordatadetails.location === '8A' || donordatadetails.location === '8B' ||
          donordatadetails.location === '9A' ||  donordatadetails.location === '9B' 
          ?
          <div className="circle7">{donordatadetails.location}</div>
          :
          donordatadetails.location === '10A' ||  donordatadetails.location === '10B' || donordatadetails.location === '11A' || donordatadetails.location === '11B' ||
          donordatadetails.location === '12A' ||  donordatadetails.location === '12B' 
          ?
          <div className="circle10">{donordatadetails.location}</div>
          :
          donordatadetails.location === '13A' ||  donordatadetails.location === '13B' || donordatadetails.location === '14A' || donordatadetails.location === '14B' ||
          donordatadetails.location === '15A' ||  donordatadetails.location === '15B' 
          ?
          <div className="circle13">{donordatadetails.location}</div>
          :
          donordatadetails.location === '16A' ||  donordatadetails.location === '16B' || donordatadetails.location === '17A' || donordatadetails.location === '17B' ||
          donordatadetails.location === '18A' ||  donordatadetails.location === '18B' 
          ?
          <div className="circle16">{donordatadetails.location}</div>
          :
          donordatadetails.location === '19A' ||  donordatadetails.location === '19B' || donordatadetails.location === '20A' || donordatadetails.location === '20B' ||
          donordatadetails.location === '21A' ||  donordatadetails.location === '21B' 
          
          ?
          <div className="circle19">{donordatadetails.location}</div>
          :
          donordatadetails.location === '22A' ||  donordatadetails.location === '22B' || donordatadetails.location === '23A' || donordatadetails.location === '23B' ||
          donordatadetails.location === '24A' ||  donordatadetails.location === '24B' 
          
          ?
          <div className="circle22">{donordatadetails.location}</div>
          :
          donordatadetails.location === '25A' ||  donordatadetails.location === '25B' || donordatadetails.location === '26A' || donordatadetails.location === '26B' ||
          donordatadetails.location === '27A' ||  donordatadetails.location === '27B' 
          
          ?
          <div className="circle25">{donordatadetails.location}</div>
          :
          donordatadetails.location === '28A' ||  donordatadetails.location === '28B' || donordatadetails.location === '29A' || donordatadetails.location === '29B' ||
          donordatadetails.location === '30A' ||  donordatadetails.location === '30B' 
          
          ?
          <div className="circle28">{donordatadetails.location}</div>
          :
          donordatadetails.location === '31A' ||  donordatadetails.location === '31B' || donordatadetails.location === '32A' || donordatadetails.location === '32B' ||
          donordatadetails.location === '33A' ||  donordatadetails.location === '33B' 
          
          ?
          <div className="circle31">{donordatadetails.location}</div>
          :
          donordatadetails.location === '34A' ||  donordatadetails.location === '34B' || donordatadetails.location === '35A' || donordatadetails.location === '35B' ||
          donordatadetails.location === '36A' ||  donordatadetails.location === '36B' 
          
          ?
          <div className="circle34">{donordatadetails.location}</div>
          :
          donordatadetails.location === '37A' ||  donordatadetails.location === '37B' || donordatadetails.location === '38A' || donordatadetails.location === '38B' ||
          donordatadetails.location === '39A' ||  donordatadetails.location === '39B' 
          
          ?
          <div className="circle37">{donordatadetails.location}</div>
          :
          donordatadetails.location === '40A' || donordatadetails.location === '40B' 
          ?
          <div className="circle40">{donordatadetails.location}</div>
          :
          donordatadetails.location === '1A || 2B' 
          ? 
          <div className="circle a2">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M003' || donordatadetails.location === 'M004' || donordatadetails.location === 'M005' || donordatadetails.location === 'M006'   
          ?
          <div className="circleM3">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M007' || donordatadetails.location === 'M008' || donordatadetails.location === 'M009' || donordatadetails.location === 'M010'   
          ?
          <div className="circleM7">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M011' || donordatadetails.location === 'M012' || donordatadetails.location === 'M013' || donordatadetails.location === 'M014'   
          ?
          <div className="circleM11">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M015' || donordatadetails.location === 'M016' || donordatadetails.location === 'M017' || donordatadetails.location === 'M018'   
          ?
          <div className="circleM15">{donordatadetails.location}</div>
       
          :
          donordatadetails.location === 'M019' || donordatadetails.location === 'M020' || donordatadetails.location === 'M021' || donordatadetails.location === 'M022'   
          ?
          <div className="circleM19">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M023' || donordatadetails.location === 'M024' || donordatadetails.location === 'M025' || donordatadetails.location === 'M026'   
          ?
          <div className="circleM23">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M027' || donordatadetails.location === 'M028' || donordatadetails.location === 'M029' || donordatadetails.location === 'M030'   
          ?
          <div className="circleM27">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M035' || donordatadetails.location === 'M036' || donordatadetails.location === 'M037' || donordatadetails.location === 'M038' 
          ?
          <div className="circleM35">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M039' || donordatadetails.location === 'M040' || donordatadetails.location === 'M041' || donordatadetails.location === 'M042' 
          ?
          <div className="circleM39">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M043' || donordatadetails.location === 'M044' || donordatadetails.location === 'M045' || donordatadetails.location === 'M046' 
          ?
          <div className="circleM43">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M051' || donordatadetails.location === 'M052' || donordatadetails.location === 'M053' || donordatadetails.location === 'M054' 
          ?
          <div className="circleM51">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M063' || donordatadetails.location === 'M064' || donordatadetails.location === 'M065' || donordatadetails.location === 'M066' 
          ?
          <div className="circleM63">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M075' || donordatadetails.location === 'M076' || donordatadetails.location === 'M077' || donordatadetails.location === 'M078' 
          ?
          <div className="circleM75">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M079' || donordatadetails.location === 'M080' || donordatadetails.location === 'M081' || donordatadetails.location === 'M082' 
          ?
          <div className="circleM79">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M087' || donordatadetails.location === 'M088' || donordatadetails.location === 'M089' || donordatadetails.location === 'M090' 
          ?
          <div className="circleM87">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M091' || donordatadetails.location === 'M092' || donordatadetails.location === 'M093' || donordatadetails.location === 'M094' 
          ?
          <div className="circleM91">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M103' || donordatadetails.location === 'M104' || donordatadetails.location === 'M105' || donordatadetails.location === 'M106'   
          ?
          <div className="circleM103">{donordatadetails.location}</div>
          :
          donordatadetails.location === 'M115' || donordatadetails.location === 'M116' || donordatadetails.location === 'M117' || donordatadetails.location === 'M118'   
          ?
          <div className="circleM115">{donordatadetails.location}</div>
          : 
          donordatadetails.location === 'Joyful Mysteries'    
          ?
          <div></div>
          : 
          donordatadetails.location === 'Luminous Mysteries'    
          ?
          <div></div>
          : 
          donordatadetails.location === 'Sorrowful Mysteries'    
          ?
          <div></div>
          : 
          donordatadetails.location === 'Glorious Mysteries'    
          ?
          <div></div>
          : 
          donordatadetails.location === 'Marian Gardens Bench'    
          ?
          <div></div>
          : 
          <div>''</div>
        }
        {


        
          <img src={donordatadetails.donormapimage_b} alt="Map B" />
         
        }

        
        
       


    </div>
  );
}        

export default Location;